import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material'
import { apis, isValidDate, MAX_YEAR, MIN_YEAR, runRemotePostRequest } from '../utils';

const matrixNumsMap: Map<number, string> = new Map([
    [0, '1'],
    [1, '4'],
    [2, '7'],
    [3, '2'],
    [4, '5'],
    [5, '8'],
    [6, '3'],
    [7, '6'],
    [8, '9']
])
const getNumsMatrix = (nums: string[]): (string[])[] => {
    const result: (string[])[] = []

    matrixNumsMap.forEach((v, k) => {
        result.push([nums.find(num => num[0] === v) || '-'])
    })

    return result
}

export const GetNumsByDate = () => {
    const [dateValue, setDateValue] = useState('')
    const [nums, setNums] = useState([])
    const [submitActive, updateSubmitActive] = useState(true)

    const getNums = () => {
        updateSubmitActive(false)
        runRemotePostRequest(apis.getNumsByDate, JSON.stringify({ date: dateValue }))
            .then(setNums)
            .then(() => updateSubmitActive(true))
    }
    console.log(getNumsMatrix(nums))

    return (
        <Grid container spacing={2} style={{
            // background: '#6d9bc3',
            height: '50vh'
        }}>
            <Grid item xs={12} md={12}>
                <Typography align='center' variant='h4'>
                    Введите дату в формате 'dd.mm.yyyy', от {MIN_YEAR} до {MAX_YEAR} гг.
                </Typography>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        required
                        error={!!dateValue.length && !isValidDate(dateValue)}
                        id='outlined-required'
                        label='Required'
                        value={dateValue}
                        onChange={e => setDateValue(e.target.value)}
                    />
                    <Button
                        disabled={!(isValidDate(dateValue) && submitActive)}
                        variant='contained'
                        style={{ marginLeft: '10px' }}
                        onClick={getNums}
                    >Отправить</Button>
                </div>
            </Grid>
            <Grid item xs={4} md={4}></Grid>
            <Grid item xs={4} md={4} style={{ border: '1px solid #6D9BC3' }}>
                <Typography align='center' variant='h5'>
                    Результат: {nums.join('.')}
                </Typography>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                    {
                        getNumsMatrix(nums).map((num, i) => (
                            <div key={i} style={{ margin: '0 5px', width: '30%' }}>{num}</div>
                        ))
                    }
                </div>
            </Grid>
            <Grid item xs={4} md={4}></Grid>
        </Grid>
    )
}
