import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material'
import { apis, isValidDate, MAX_YEAR, MIN_YEAR, runRemotePostRequest } from '../utils';

const matrixNumsMap: Map<number, string> = new Map([
    [0, '1'],
    [1, '4'],
    [2, '7'],
    [3, '2'],
    [4, '5'],
    [5, '8'],
    [6, '3'],
    [7, '6'],
    [8, '9']
])
const getNumsMatrix = (nums: string[]): (string[])[] => {
    const result: (string[])[] = []

    matrixNumsMap.forEach((v, k) => {
        result.push([nums.find(num => num[0] === v) || '-'])
    })

    return result
}

export const GetAnalyzeByDate = () => {
    const [dateValue, setDateValue] = useState('')
    const [analyzeData, setAnalyzeData] = useState({
        nums: [],
        mx_analyze: {},
        nums_analyze: {}
    })
    const [submitActive, updateSubmitActive] = useState(true)

    const getNums = () => {
        updateSubmitActive(false)
        runRemotePostRequest(apis.getAnalyzeByDate, JSON.stringify({ date: dateValue }))
            .then(setAnalyzeData)
            .then(() => updateSubmitActive(true))
    }

    const resultSection = (
        <Grid item xs={8} style={{ border: '1px solid #6D9BC3' }}>
                <Typography align='center' variant='h5'>
                    Результат: {analyzeData.nums.join('.')}
                </Typography>
                <br />
                <Grid rowSpacing={1}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                        {
                            getNumsMatrix(analyzeData.nums).map((num, i) => (
                                <div key={i} style={{ margin: '0 5px', width: '30%' }}>{num}</div>
                            ))
                        }
                    </div>
                </Grid>
                <Grid rowSpacing={1}>
                    <Typography align='center' variant='h5'>
                        Матричный анализ
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                        {
                            Object.values(analyzeData.mx_analyze).map((note: any, i) => (
                                <p key={i}>{note}</p>
                            ))
                        }
                    </div>
                </Grid>
                <Grid rowSpacing={1}>
                    <Typography align='center' variant='h5'>
                        Цифровой анализ
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                        {
                            Object.keys(analyzeData.nums_analyze).map((k: string, i) => (
                                <p key={i}>{k}: {
                                    Object.keys((analyzeData.nums_analyze as Record<string, any>)[k]).map((innerKey: string, j) => (
                                        <p key={j}><b>{innerKey}:</b> {(analyzeData.nums_analyze as Record<string, any>)[k][innerKey]}</p>
                                    ))
                                }</p>
                            ))
                        }
                    </div>
                </Grid>
                <Grid rowSpacing={1}>
                    <Typography align='center' variant='h5'>
                        Возможные переходы
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                        {
                            (analyzeData as any)?.nums_transfers?.map((numTransfer: string, i: number) => (
                                <p key={i}>{numTransfer}</p>
                            ))
                        }
                    </div>
                </Grid>
            </Grid>
    )

    return (
        <Grid container spacing={2} style={{
            // background: '#6d9bc3',
            height: '50vh'
        }}>
            <Grid item xs={12} md={12}>
                <Typography align='center' variant='h4'>
                    Введите дату в формате 'dd.mm.yyyy', от {MIN_YEAR} до {MAX_YEAR} гг.
                </Typography>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        required
                        error={!!dateValue.length && !isValidDate(dateValue)}
                        id='outlined-required'
                        label='Required'
                        value={dateValue}
                        onChange={e => setDateValue(e.target.value)}
                    />
                    <Button
                        disabled={!(isValidDate(dateValue) && submitActive)}
                        variant='contained'
                        style={{ marginLeft: '10px' }}
                        onClick={getNums}
                    >Отправить</Button>
                </div>
            </Grid>
            <br />
            <Grid item xs={2}></Grid>
            { analyzeData.nums.length ? resultSection : '' }
            <Grid item xs={2}></Grid>
        </Grid>
    )
}
