import { Grid, Typography } from '@mui/material';
import './App.css';

function App() {
    return (
        <div className='App'>
            <Grid item xs={4} md={4}></Grid>
            <Grid item xs={4} md={4}>
                <Typography align='center' variant='h4'>
                    <p>Добро пожаловать!</p>
                </Typography>
            </Grid>
            <Grid item xs={4} md={4}></Grid>
        </div>
    );
}

export default App;
