
// const domain: string = 'http://localhost:8000'
const domain: string = 'https://api.mns.lutsiak.pro'
export const apis = {
    getDatesByNum: `${domain}/api/get-dates-by-num`,
    getNumsByDate: `${domain}/api/get-nums-by-date`,
    getAnalyzeByDate: `${domain}/api/get-analyze-by-date`,
}
export const MIN_YEAR = 1900
export const MAX_YEAR = 2030

export const isValidDate = (val: string): boolean => {
    const [d, m, y] = val.split('.')
    return (
        (+d > 0 && +d <= 31) &&
        (+m > 0 && +m <= 12) &&
        (+y > MIN_YEAR && +y <= MAX_YEAR)
    )
}

export const runRemotePostRequest = (
    api: string,
    data: string
) => {
    const authKey: string = localStorage.getItem('authKey') as string // try to set auth

    return fetch(api, {
        method: 'post',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'content-type': 'application/json',
            'Authorization': authKey
        },
        body: data
    })
        .then(res => res.json())
}

export const checkIfAuthorizedActionsAllowed = (): boolean => {
    return !!localStorage.getItem('authKey')
}

