import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material'
import { apis, runRemotePostRequest } from '../utils';

const maxNumsLen: number = 35

export const GetDatesByNum = () => {
    const [numsValue, setNumsValue] = useState('')
    const [dates, setDates] = useState([])
    const [submitActive, updateSubmitActive] = useState(true)
  
    const getDates = () => {
      updateSubmitActive(false)
      runRemotePostRequest(apis.getDatesByNum, JSON.stringify({ nums: numsValue }))
        .then(setDates)
        .then(() => updateSubmitActive(true))
    } 
  
    return (
      <Grid container spacing={2} style={{
        // background: '#6d9bc3',
        height: '50vh'
      }}>
        {/* <Grid item xs={12} md={12}>
          <Typography align='center' variant='h4'>
            Получить подходящие даты по набору цифр
          </Typography>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Typography align='center' variant='h6'>
            Введите цифры с разделением через точку
          </Typography>
          <br />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TextField
              required
              id='outlined-required'
              label={`Required, max ${maxNumsLen}`}
              value={numsValue}
              onChange={e => e.target.value.match(/^([0-9]+\.?([0-9]+)?){1,}$/) ? setNumsValue(e.target.value) : null }
            />
            <Button
              disabled={!(numsValue.length < maxNumsLen && submitActive)}
              variant='contained'
              style={{ marginLeft: '10px' }}
              onClick={getDates}
            >Отправить</Button>
          </div>
        </Grid>
        <Grid item xs={4} md={4}></Grid>
        <Grid item xs={4} md={4} style={{ border: '1px solid #6D9BC3' }}>
          <Typography align='center' variant='h5'>
            Результат
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            {
              dates.map((date, i) => (
                <div key={i} style={{ margin: '0 5px' }}>{date}</div>
              ))
            }
          </div>
        </Grid>
        <Grid item xs={4} md={4}></Grid>
      </Grid>
    )
}